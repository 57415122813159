/* You can add global styles to this file, and also import other style files */
@tailwind base
@tailwind components
@tailwind utilities

h1
  font-size: 2rem
  font-weight: bold
  margin-bottom: 1rem

h2
  font-size: 1.7rem
  font-weight: bold
  margin-bottom: 0.75rem

h3
  font-size: 1.5rem
  font-weight: 600
  margin-bottom: 0.5rem

h4
  font-size: 1.25rem
  font-weight: bold
  margin-bottom: 0.5rem

h5
  font-size: 1rem
  font-weight: bold
  text-transform: uppercase
  margin-bottom: 0.5rem

h6
  font-size: 1rem
  font-weight: bold
  color: #888
  text-transform: uppercase
  margin-bottom: 0.5rem

/* Paragraphs */

p
  font-size: 1rem
  line-height: 1.5
  margin-bottom: 1rem

/* Lists */

ul
  list-style-type: disc
  margin-left: 1.5rem
  margin-bottom: 1rem

ol
  list-style-type: decimal
  margin-left: 1.5rem
  margin-bottom: 1rem

li
  margin-bottom: 0.5rem
h1
  font-size: 2rem
  font-weight: bold
  margin-bottom: 1rem

h2
  font-size: 1.7rem
  font-weight: bold
  margin-bottom: 0.75rem

h3
  font-size: 1.5rem
  font-weight: 600
  margin-bottom: 0.5rem

h4
  font-size: 1.25rem
  font-weight: bold
  margin-bottom: 0.5rem

h5
  font-size: 1rem
  font-weight: bold
  text-transform: uppercase
  margin-bottom: 0.5rem

h6
  font-size: 1rem
  font-weight: bold
  color: #888
  text-transform: uppercase
  margin-bottom: 0.5rem

/* Paragraphs */

p
  font-size: 1rem
  line-height: 1.5
  margin-bottom: 1rem

/* Lists */

ul
  list-style-type: disc
  margin-left: 1.5rem
  margin-bottom: 1rem

ol
  list-style-type: decimal
  margin-left: 1.5rem
  margin-bottom: 1rem

li
  margin-bottom: 0.5rem

html 
  height: 100% /* Fallback CSS for IE 4-6 and older browsers. Note: Without this setting, body below cannot achieve 100% height. */
  height: 100vh/* Overrides 100% height in modern HTML5 browsers and uses the viewport's height. Only works in modern HTML5 browsers */
  

body 
  height: auto /* Allows content to grow beyond the page without overflow */
  width: auto /* Allows content to grow beyond the page without overflow */
  min-height: 100% /* Starts web page with 100% height. Fallback for IE 4-6 and older browsers */
  min-height: 100vh/* Starts web page with 100% height. Uses the viewport's height. Only works in modern HTML5 browsers */
  overflow-y: scroll/* Optional: Adds an empty scrollbar to the right margin in case content grows vertically, creating a scrollbar.  Allows for better width calculations, as the browser pre-calculates width before scrollbar appears, avoiding page content shifting.*/
  margin: 0
  padding: 0

code.wallet
  padding: 0.5rem
  background-color: #533482
  color: #c5abed
  border-radius: 0.5rem /* 8px */


.repo-content ul
  list-style: circle

.repo-content li 
  margin: 0.6rem  /* Adds padding to each list item */
  
